
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
    name: "password-reset",
    components: {
        Field,
        Form,
        ErrorMessage,
    },
    setup() {
        const store = useStore();
        const router = useRouter();

        const submitButton = ref<HTMLElement | null>(null);

        //Create form validation object
        const forgotPassword = Yup.object().shape({
            email: Yup.string().email().required().label("Email"),
        });

        //Form submit function
        const onSubmitForgotPassword = (values) => {
            // Activate loading indicator
            submitButton.value?.setAttribute("data-kt-indicator", "on");

            // dummy delay
            setTimeout(() => {
                // Send login request
                store
                    .dispatch(Actions.FORGOT_PASSWORD, values)
                    .then(() => {
                        Swal.fire({
                            text: "A link to reset your password has been sent yo your email address",
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: "OK !",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-primary",
                            },
                        }).then(function () {
                            // Go to page after successfully login
                            // router.push({ name: "change-password" });
                        });
                    })
                    .catch(() => {
                        // Alert then login failed
                        Swal.fire({
                            text: store.getters.getErrors.global,
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "Try again!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-danger",
                            },
                        });
                    });

                submitButton.value?.removeAttribute("data-kt-indicator");
            }, 2000);
        };

        return {
            onSubmitForgotPassword,
            forgotPassword,
            submitButton,
        };
    },
});
